/*Color Variables*/
:root {
    --color-primary: #5DCDF5;
    --color-bg-gray: #C5C5C5;
    --color-bg-light-gray: #EFECEC;
    --color-bg-dark-gray: #D9D9D9;
    --color-text-light-gray: #9d9d9d;
    --color-bg-drawer-gray: #EEECEC;
    --color-inactive: #9C9C9C;
    --color-active: #495FAB;
    --color-grey: #9D9D9D;
    --color-white: #FFFFFF;
}