.subs_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px;
    max-width: calc(100% - 58px);
    margin-left: auto;
    padding: 0 20px;
}

.subs_card,
.card-body {
    background-color: #f9f9f9;
    padding-bottom: 0 !important;
    overflow: hidden;
}
.subs_card:hover{
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25), -4px -4px 4px 0px rgba(229, 229, 229, 0.25);
    border-radius: 29px;
}

.subs_card .my-2 {
    margin-bottom: 0 !important;
}

.subs_card .p-3 {
    padding-right: 7px !important;
    padding-left: 7px !important;
}

.subs_card:first-child .card-body {
    background: #FFF;
}

.free-plan-card.btn.btn-primary.subs_primary{
    background: var(--color-bg-dark-gray) !important;
    color: var(--color-grey) !important;
}

.subs_card:first-child {
    border-radius: 29px;
    background: #FFF;
}

.subs_card:last-child .subs_primary {
    color: #fff;
}

.subs_title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1;
    top: 15px;
}

.subs_title>span {
    color: #969696;
    font-size: 14px;
    font-weight: 300;
    border-left: 5px solid #5DCDF5;
    padding-left: 5px;
}

.subscription_page_header {
    color: #000;
    margin-top: 6.1vh;
    font-size: 3.5714285714285716vh;
    margin-bottom: 5.022321428571429vh;
}

.subscription_page_sub_header {
    display: block;
    font-size: 2.0089285714285716vh;
}

.subs_primary {
    outline: 0;
    appearance: none;
    color: white ;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    border: 0;
    letter-spacing: -0.198px;
    border-radius: 100px;
    margin-bottom: 7px;
    padding: 14px;
    width: 100%;
    background: var(--color-active);
}

.subs_primary:hover {
    background: linear-gradient(152.26deg, #495FAB 17.23%, #5DCDF5 93.4%);
    box-shadow: 4px 4px 4px 0px rgba(87, 87, 87, 0.25), -4px -4px 4px 0px #FFF;
}
.infinite-btn.btn.btn-primary.subs_primary {
    background: var(--color-active);
    color: #fff;
}
.infinite-btn.btn.btn-primary.subs_primary:hover {
    background: linear-gradient(152.26deg, #495FAB 17.23%, #5DCDF5 93.4%);
}

.infinite-selected.btn.btn-primary.subs_primary{
    background-color: #EEECEC;
    color: var(--color-active);

}

.infinite-selected.btn.btn-primary.subs_primary:hover{
    background: linear-gradient(152.26deg, #495FAB 17.23%, #5DCDF5 93.4%);
    color: white ;
}
.subs_primary.btn-check:checked+.subs_primary.btn,
:not(.btn-check)+.subs_primary.btn:active,
.subs_primary.btn:first-child:active,
.subs_primary.btn.active,
.subs_primary.btn.show {
    background: #fff;
    color: #495FAB;
}

.subscription_desc {
    font-size: 18px;
    line-height: 1.35;
    height: 25.669642857142858vh;
    margin-top: 2.232142857142857vh;
    padding: 0 1.5625vw;
}

.selected-plan-btn{
    background: var(--color-bg-dark-gray) !important;
    font-weight: bold; 
    content: "Purchasing";
    color: black !important;
}

.unselected-plan-btn{
    background: var(--color-bg-dark-gray) !important;
    font-weight: bold;
    content: "Purchasing";
    color: var(--color-grey) !important;
}
.subsPhoto {
    height: 27.901785714285715vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 3.147953830010493vh;
}

.subsPhoto::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 35px;
    width: 100%;
    background: linear-gradient(180deg, rgba(249, 249, 249, 0.00) 0%, #F9F9F9 100%);
}

.subsPhoto img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
