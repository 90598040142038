.faq_col{
    overflow-Y: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.faq_wrap {
    max-width: 582px;
    width: 100%;
    margin: 5vh auto;
    margin: auto;
}

.faq_wrap .faq-content {
    margin-bottom: 5vh;
}
.faq_wrap .accordion-body iframe{
    width: 100%;
    margin: 10px 0;
}
.faq_wrap .faq-content .title {
    font-size: 30px;
    text-align: center;
    line-height: 1.5;
    font-weight: 400;
}
.faq_wrap .faq-content .title span {
    display: block;
    font-size: 22px;
}
.faq_wrap .faq-content .title span .hp-contact-btn{
    background: linear-gradient(139deg, #5DCDF5 23.13%, #5DCDF5 83.94%);
    border: 0;
    padding: 10px 30px;
    margin-top: 10px;
    color: #fff;
    box-shadow: 4px 4px 4px 0px rgba(87, 87, 87, 0.25), -4px -4px 4px 0px #FFF;
    border-radius: 50px;
    min-width: 200px;
}
.faq_wrap .faq-content .title span .hp-contact-btn:hover{
    background: linear-gradient(139deg, #5DCDF5 23.13%, #495FAB 83.94%);
    color: #fff;
}

.faq_wrap .faq-content .title span a {
    text-decoration: none;
    color: #495fab;
}
 .accordion .accordion-item:not(:last-child){
    margin-bottom: 10px;
}
.faq_wrap .accordion h2.accordion-header{
}
.faq_wrap .accordion .accordion-item{
}
.faq_wrap .accordion-item .accordion-button:focus{
    border: 0;
    border-color: transparent;
    box-shadow: none;
}
.faq_wrap .accordion-button{
    background-color: #efecec;
}