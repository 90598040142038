:root {
    --right-offset: 11px;
}

.icon-title-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: auto;
    max-width: 635px;
    width: 100%;
    padding-top: 2vh;
    cursor: pointer;

}
.icon-title-wrap .hp-icon-title{
    color: var(--color-active);
    font-size: 20px;
    font-weight: 300;
}
.hp-mulitplier-input{
    margin-top: 30px;
    padding-bottom: 60px;
}
.hp-mulitplier-input .hp-title-sub{
    color: var(--color-bg-gray);
    font-size: 12px;
    font-weight: 400;
}
.HPsteps-wrap {
    padding-left: 15px;
    /* margin-top: 15px; */
    height: calc(100vh - 20vh);
    display: flex;
    flex-direction: column;
}
.hp-conversation-wrapper {
    max-width: calc(520px + (588 - 520) * ((100vw - 320px) / (1920 - 320)));
    width: 100%;
    margin: 20px auto 0;
}

.HPsteps-wrap .HP-step {
    display: flex;
    gap: 15px;
}
.HPsteps-wrap .HP-step:not(:last-child) {
    margin-bottom: 15px;
}
.HPsteps-wrap .HP-step:last-child {
    height: 100%;
}

.hp-content{
    margin-top: 5px;
}
.hp-content .flex-box {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
}
.hp-icon {
    width: 36px;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 15px;
}
.hp-icon label{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hp-icon svg,
.hp-icon svg path{
    transition: all 0.3s ease;
    fill: var(--color-bg-gray);
}
.vertical_line {
    width: 3px;
    min-height: 20px;
    height: 100%;
    background-color: var(--color-bg-gray);
    border-radius: 2px;
    margin: auto;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.38) inset, -1px -1px 1px 0px rgba(255, 255, 255, 0.33) inset;
    transition: all 0.3s ease;
}
.HP-step.active .vertical_line{
    background-color: var(--color-primary);
}
.HP-step.active .hp-title,
.HP-step.active .hp-mulitplier-input .hp-title-sub,
.HP-step.active .hp-mulitplier-input .HPinputNum{
    color: var(--color-active);
}
.HP-step.active .hp-title .subsubTitle{
    color: red;
}
.HP-step.active .hp-icon svg,
.HP-step.active .hp-icon svg path{
    fill: var(--color-active);
}

.HP-step .hp-title {
    color: var(--color-bg-gray);
    font-size: 20.5px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: all 0.3s ease;
}
.HP-step .hp-title .subTitle{
    display: block;
    font-size: 16px;
    font-weight: 400;
}
.HP-step .hp-title .subsubTitle{
    display: block;
    font-size: 10px;
    font-weight: 400;
}
.hp-mulitplier-input .HPinputNum{
    min-height: 27px;
    border-radius: 3px;
    background: #FFF;
    box-shadow: 2px 2px 3px 0px rgba(199, 199, 199, 0.25), -2px -2px 3px 0px rgba(255, 255, 255, 0.25);
    padding: 10px;
    border: 0;
    appearance: none;
    outline: none;
    font-size: 16px;
    color: var(--color-bg-gray);
}
.hpm-input-wrap {
    position: relative;
}

.hpm-input-wrap .arrow-box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
.hpm-input-wrap input::-webkit-outer-spin-button,
.hpm-input-wrap input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hpm-input-wrap input[type=number] {
  -moz-appearance: textfield;
}

.hpm-input-wrap .arrow-box .countArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.checkWrap .checkBox{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.checkWrap .checkBox .onOff {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 3px 7px 0;
    pointer-events: none;
}
.checkWrap .checkBox .onOff label{
    font-family: Montserrat;
    font-weight: 700;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
}

.checkBox input[type="checkbox"] {
    position: relative;
    width: 58px;
    height: 21px;
    -webkit-appearance: none;
    outline: none;
    transition: 0.7s;
    border-radius: 15.5px;
    background: #A9A9A9;
    box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.25) inset, 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
    cursor: pointer;
    margin-top: 3px;
}

.checkBox input:checked[type="checkbox"] {
  background: var(--color-primary);
}

.checkBox input[type="checkbox"]:before {
  content: '';
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 20px;
  top: 50%;
  transform: translateY(-50%) scale(1.1);
  left: 0;
  background: #ffffff;
  transition: .5s;
  box-shadow: 1px 1px 1px 0px rgba(187, 187, 187, 0.25) inset, -1px -1px 1px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.25), 0px 4px 4px 0px rgba(134, 134, 134, 0.25);
  z-index: 2;
}

.checkBox input:checked[type="checkbox"]:before {
  left: 31px;
}

/* GP input text box */
.customDropBtn .dropdown-toggle::after {
    display: none;
}

.GPInputWrapper .input-group{
    align-items: center;
    gap: 5px;
}

.customDropBtn .dropdown-toggle:focus
{
    background-color: transparent; 
    color: gray;
}

.customDropBtn  .show{
    border-radius: 24px;
    border: none;
}

.customDropBtn .dropdown-menu{
    box-shadow: -2px -2px 2px 0px rgba(203, 203, 203, 0.25) inset, 2px 2px 2px 0px #FEFEFE inset, -3px -3px 3px 0px rgba(255, 255, 255, 0.81), 3px 3px 3px 0px rgba(207, 207, 207, 0.25);
    transform: translate(-77%, -1px) !important;
    width: 240px;
    padding: 0 1.1vw;
    padding-top: 3vh;
    padding-bottom: 2vh;
}


.focused{
    box-shadow: -4px -4px 4px 0px #FFF, 4px 4px 4px 0px rgba(87, 87, 87, 0.25);
    border-radius: 30px;
    background: #FFF;
}

.focused-input{
    border: 1px solid #5DCDF5;
    border-radius: 5px;
}
.GPInputWrapper{
    max-width: 588px;
    min-width: 588px;
    border-radius: 30px;
    background: #FFF;
    margin: 0 auto;
    z-index: 5;
}

.GPInputWrapperFixed {
    /*display: flex;
    align-items: center;
    justify-content: center;
    */position: fixed;
    bottom: 5vh;
    /*right: var(--right-offset);
    *//*width: 100%;
    */width: 588px;
    left: 50%;
    transform: translateX(-45%);
}


.GPInputWrapper .aiDropWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 5px;
    width: 120px;
    height: 41px;
    justify-content: space-between;
}
.GPInputWrapper .aiDropWrap .dropTitle {
    text-align: left;
    display: block;
    font-size: 14px;
    color: var(--color-bg-gray);
    font-weight: 400;
    line-height: 1;
}
.GPInputWrapper .aiDropWrap .dropTitle .dropSubTitle {
    display: block;
    color: #A7A7A7;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    letter-spacing: -0.165px;
}
.GPInputWrapper .GPInputText,
.GPInputWrapper .GPInputText:focus{
    outline: 0;
    appearance: none;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 7px 0;
    padding-left: 30px;
    resize: none;
    max-height: 250px;
    font-family: Blinker;
    overflow-y: auto;
}
.GPInputWrapper:hover{
    box-shadow: -4px -4px 4px 0px #FFF, 4px 4px 4px 0px rgba(87, 87, 87, 0.25);
    border-radius: 30px;
    background: #FFF;

}

.GPInputWrapper .GPInputTextnew,
.GPInputWrapper .GPInputTextnew:focus{
    outline: 0;
    appearance: none;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 7px 0;
    padding-left: 30px;
    resize: none;
    min-height: 70vh;
    height: 70vh;
    max-height: 70vh !important;
    font-family: Montserrat;
    overflow-y: auto;
}

.GPInputWrapper .GPInputTextexisting,
.GPInputWrapper .GPInputTextexisting:focus{
    outline: 0;
    appearance: none;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 7px 0;
    padding-left: 30px;
    resize: none;
    height: 85vh !important;
    min-height: 85vh !important;
    max-height: 85vh;
    font-family: Montserrat;
    overflow-y: auto;
}
.GPInputWrapper .GPInputText::-webkit-input-placeholder{
    font-size: 11px;
    font-family: Montserrat;
    transform: translateY(3px);
    -webkit-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    -webkit-transform: translateY(3px);
}

.gpInputWrapperFocus{
    box-shadow: -2px -2px 2px 0px #FFF, 2px 2px 2px 0px rgba(134, 134, 134, 0.25);
}

.convoTitleInput:focus,
.convoTitleInput {
    outline: none;
    height: 48px;
    max-width: 355px;
    width: 100%;
    vertical-align: middle;
    border: none;
    border-radius: 5px;
    background: #FFF;
    box-shadow: -1px -1px 1px 0px #FFF inset, 1px 1px 1px 0px rgba(134, 134, 134, 0.25) inset;
    margin: auto;
    color: #C7C7C7;
    font-size: 18px;
    padding: 0 15px;
}

.gp-input-group {
    position: relative;    
    max-width: 355px;
    width: 100%;
    margin: 0 auto;
}
.gp-input-filled {
    position: absolute;
    left: 0;
    width: 100%;
}
.gp-input-filled input{
    outline: none;
    height: 48px;
    max-width: 355px;
    width: 100%;
    vertical-align: middle;
    border: none;
    border-radius: 5px;
    background: #FFF;
    box-shadow: -1px -1px 1px 0px #FFF inset, 1px 1px 1px 0px rgba(134, 134, 134, 0.25) inset;
    margin: auto;
    font-size: 14px;
    padding: 24px 10px 9px;
}
.gp-input-label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.4s ease;
    color: #9D9D9D;
    font-size: 14px;
}

.gp-input-label.email-error-label{
    font-size: 12px;
    color:red;
    transform: translateY(-20px);
    font-family: Montserrat;

}

.gp-input-filled input:hover{
    box-shadow: 1px 1px 1px 0px #FFF inset, -1px -1px 1px 0px rgba(134, 134, 134, 0.25) inset;
}

.gp-input-filled input:focus{
    border: 1px solid #5DCDF5;
}
.gp-input-filled.filled input{
    box-shadow: none;
}
.gp-input-filled.filled:hover input {
    box-shadow: 1px 1px 1px 0px #FFF inset, -1px -1px 1px 0px rgba(134, 134, 134, 0.25) inset;
}

.gp-input-filled input:focus + .gp-input-label,
.gp-input-filled input:valid + .gp-input-label{
    transform: translateY(-20px);
    font-size: 12px;
}

.gp-input-filled input:-webkit-autofill {
    background-color: #fff !important;
    color: initial !important;
}

.gp-input-filled.filled input:-webkit-autofill {
    background-color: #fff !important;
    color: initial !important;
}

textarea.GPInputText {
    scrollbar-width: 10px;
    scrollbar-color: #D9D9D9 #f1f1f1;
  }
  
  /* Chrome, Edge, and Safari */
  textarea.GPInputText::-webkit-scrollbar {
    width: 10px;
  }
  
  textarea.GPInputText::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  textarea.GPInputText::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 10px;
    border: 3px solid #e0e0e0;
  }

  textarea.GPInputTextnew {
    scrollbar-width: 10px;
    scrollbar-color: #D9D9D9 #f1f1f1;
  }
  
  /* Chrome, Edge, and Safari */
  textarea.GPInputTextnew::-webkit-scrollbar {
    width: 10px;
  }
  
  textarea.GPInputTextnew::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  textarea.GPInputTextnew::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 10px;
    border: 3px solid #e0e0e0;
  }

.GPInputTextWrap{
    flex: 1 1 auto;
}

.GPInputWrapper  button {
    border-radius: 0;
    border: 0;
    background-color: transparent;
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: 'Montserrat';
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

/* .GPInputWrapper  button:focus{
    outline-color: skyblue;
    outline-width: 3px;
    outline-style: solid;
} */

.GPInputWrapper  button:active{
    background-color: transparent;
}
.GPInputWrapper  .feedBtnWrap  button {
    position: relative;
    line-height: 1;
    border-radius: 30px;
    opacity: 1;
    font-size: 11px;
    font-weight: 400;
    line-height: 150%; /* 15px */
    letter-spacing: -0.11px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 3px 0;
}
.GPInputWrapper  .feedBtnWrap  button:hover{
    box-shadow: 2px 2px 2px 0px rgba(134, 134, 134, 0.25), -1px -1px 1px 0px #FFF;
} 

.tooltip-btn{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 90%;
    width: max-content;
    background-color: #21285F;
    border: 1px solid #21285F;
    border-radius: 3px;
    font-size: 0.7rem;
    color: white;
    margin: auto;
    margin-bottom: 0.2rem;
    z-index: 5;
    right: -2%;
}

/* .GPInputWrapper .feedBtnWrap  button:disabled{
    background: transparent;
    box-shadow: 2px 2px 2px 0px rgba(205, 205, 205, 0.25) inset, -2px -2px 2px 0px rgba(245, 245, 245, 0.25) inset;
    cursor:default !important;
} */



.GPInputWrapper .feedBtnWrap  button:focus-visible{
    outline-color: transparent !important;
    outline-width: 0 !important;
} 
/* 
.GPInputWrapper .feedBtnWrap  button:hover:disabled{
    background-color: #9D9D9D !important;
    color: #fff !important;
} */



/* 
.disable-btn button:focus-visible,
.enter-text-btn button:focus-visible,
.text-area-btn button:focus-visible
{
    outline-color: skyblue !important;
    outline-width: 3px ;
    outline-style: solid;
}
 */

.disable-btn button:hover,
.job-in-progress-btn button
{
    outline-color: transparent !important;
    background-color: #9D9D9D !important;
    color: #fff !important;
    box-shadow: none !important;
}


.disable-btn button:focus-visible{
    outline-color: transparent !important;
    background-color: #9D9D9D !important;
    color: #fff !important;
    outline-color: skyblue !important;
    outline-width: 3px ;
    outline-style: solid;
}

.disable-btn button{
    outline-color: transparent ;
    /* outline-width: 1px !important;  */
    background: transparent;
    box-shadow: 2px 2px 2px 0px rgba(205, 205, 205, 0.25) inset, -2px -2px 2px 0px rgba(245, 245, 245, 0.25) inset;
    cursor:default !important;

}

.disable-btn button:hover svg path,
.job-in-progress-btn button svg path
{
    fill: white;
}

.GPInputWrapper .feedBtnWrap  button:hover:disabled svg path{
    fill: white !important;
}

.color-disable button{
    color: var(--color-bg-gray) !important;
}

.GPInputWrapper .feedBtnWrap.active  button svg path{
    fill: var(--color-active);
}
.GPInputWrapper .feedBtnWrap.active  button{
    background: radial-gradient(121.42% 76.06% at 40.91% 13.89%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(158deg, rgba(93, 205, 245, 0.55) -6.13%, rgba(73, 95, 171, 0.55) 42.46%);
    /* color: var(--color-active); */
    fill: var(--color-active);

}

.GPInputWrapper .feedBtnWrap  button svg{
    margin-left: 8px;
}


.customDropBtn .dropdown-item:not(.disabled){
    color: #495FAB;
}

.dropdown-menu .listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;
    font-family: 'Montserrat';
}

.dropdown-menu .listItem .modelName {
    font-weight: 600;
}

@media (min-width: 1250px) {
    :root {
      --right-offset: 11px;
    }
  }

.error-font{
    padding-left: 11px;
    color: var(--Red, #EB2834) !important;
    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    letter-spacing: -0.132px;
}
.gp-input-label{
    font-size: 12px;
    font-family: 'Montserrat';
    transform: translateY(-20px);

}

.gp-input-label.error-label{
    font-size: 12px;
    color:red;
    transform: translateY(-20px);
    font-family: 'Montserrat';
}

.longtext{
    margin-right: 2px;
    margin-bottom: 6px;
}

.dropzone {
    /* border-radius: 8px; */
    /* border: 1px dashed var(--Grey-Grey, #9D9D9D); */
    /* text-align: center; */
    margin: auto;
    width: 588px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.file-card-tooltip {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 68px;
    width: max-content;
    background-color: #21285F;
    border: 1px solid #21285F;
    border-radius: 3px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    color: white;
    margin-bottom: 0.2rem;
    z-index: 5;
    height: 24px;
    visibility: hidden;
}

.file-card-tooltip-visible {
    visibility: visible !important;
}

.tooltip-file-text {
    width: max-content;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin: 0px 15px;
}
  
  .file-thumbnail {
    width: 100px;
    height: auto;
    margin-top: 10px;
  }

  .welcome-dropbox {
    margin: 0 auto;
    position: fixed;
    bottom: 15vh;
    right: var(--right-offset);
    width: calc(100% - 2 * var(--right-offset));
    max-height: 86vh;
    overflow-y: auto;
    z-index: 2;
  }

  .welcome-dropbox::-webkit-scrollbar {
    display: none;
  }
  
  .uploaded-file-info {
    color: #4A90E2;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Animation */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .fadeIn {
    animation: fadeIn 1s ease-in-out;
  }
  
