.warningPage {
    /* background-color: lightblue; */
    height: 100vh;
    position: relative;
}
.warningPage::before{
    content: '';
    position: absolute;
    top: -15%;
    left: -5%;
    width: 300px;
    height: 300px;
    background: linear-gradient(138deg, #495FAB 0%, #5DCDF5 36.49%, #ACE4F8 44.33%, #DDF2F9 52.17%, #EAF5F9 60.42%, #FAFAFA 67.65%);
    z-index: -1;
}
.warningPage .pageTitle{
    text-align: center;
}
.warningPage .pageTitle.titlexl{
    font-size: calc(14px + (37 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #000;
    font-weight: 400;
    margin: 1.57vh 0 0 0;
}
.warningPage .pageTitle.titleSM{
    font-size: calc(13px + (24 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: #000;
    font-weight: 300;
}
.warningPage .pageTitleBtn{
    text-align: center;
    border-radius: 20px;
    background: #FAFAFA;
    box-shadow: 4px 4px 4px 0px rgba(173, 173, 173, 0.25), -4px -4px 4px 0px #FFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #495FAB;
    font-size: 2.34375vw;
    font-weight: 400;
    min-width: 20vw;
    padding: 2.6232948583420774vh 2.6041666666666665vw;
    margin-left: 12.239583333333334vw;
    margin-top: 3.147953830010493vh;
}
.warningPage .pageTitle span{
    display: block;
}
.warningPage .pageContent {
    text-align: center;
}
.warningPage .pageContent img {
    max-width: 55vh;
    width: 100%;
    margin: 2.1vh auto;
}