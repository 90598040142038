.hyper-menu {
    right: initial !important;
    padding: 6px;
}

.hm-item p {
    margin: 0;
    opacity: 0;
    margin-left: 8px;
}

.hm-iconbtn {
    border-radius: 9px;
    border: 1px solid #FFF;
    background: #FFF;
    height: 46px;
    width: 46px;
    transition: all 0.4s ease;
    /* box-shadow: -1px -1px 1px 0px #FFF, 1px 1px 1px 0px rgba(134, 134, 134, 0.25); */
}

.hm-iconbtn:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom_color icon{
    fill: #5DCDF5;
}

/* .hp-top-menu .hm-iconbtn{
    box-shadow: -1px -1px 1px 0px #FFF, 1px 1px 1px 0px rgba(134, 134, 134, 0.25);
} */

.navbar:hover .hm-item p,
.hm-item.active p {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.hm-item.active .btn{
    /* background: white; */
    box-shadow: none;
}

.navbar .btn:hover{
    background-color: #FFFFFF;
}

.atticus-text{
    color: #005100;
    font-weight: 700;
}

.navbar .atticus-btn.btn:hover{
    background: linear-gradient(322deg, #006400 -5.81%, #98FB98 49.08%);
    box-shadow: 1px 1px 2px 0px #006400, -1px -1px 2px 0px #85E0A3;
}
/* .hm-item.active .hm-iconbtn:hover{
    background: var(--color-active);
} */

/* .hm-item.active .icon,
.hm-item.active .icon path{
    fill: var(--bs-white);
} */
.navbar-expand .navbar-nav .nav-link{
    padding-right: 0;
    padding-left: 0;
    position: relative;
    padding-bottom: 15px;
    padding-top: 0;
}
.navbar-expand .hp-bottom-menu.navbar-nav .nav-link:last-child{
    padding-bottom: 0;
}
.btn-intro {
    position: absolute;
    left: calc(100% + 0px);
    width: max-content;
    overflow: hidden;
    padding-left: 15px;
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;
}
.btn-intro > span {
    color: var(--color-active);
    transform: translateY(10%);
    transition: all 0.5s ease;
    position: relative;
    display: block;
    font-size: 18px;
    color: #006400
}
.hyper-menu{
    background: #FAFAFA;
    z-index: 99;
    position: relative;
    transition: all 0.3s ease;
    z-index: 105;
}

.hyper-menu::before {
    position: absolute;
    width: 160px;
    height: 100%;
    content: '';
    transform: translateX(52px);
    top: 0;
    left: 10px;
    background-color: #f9f9f9;
    transition: all 0.5s ease;
    pointer-events: none;
    opacity: 0;
    background: rgb(241,241,241);
    background: linear-gradient(90deg, rgba(249,249,249,1) 85%, rgba(249,249,249,0.4990371148459384) 100%);
}
.hyper-menu:hover{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.hyper-menu:hover:before{
    pointer-events: visible;
    opacity: 1; 
}

.hyper-menu:hover .btn-intro > span{
    transform: translateY(0%);
}

.hyper-menu:hover .btn-intro{
    visibility: visible;
    opacity: 1;
}
.hm-iconbtn.btn-outline-secondary {
    border-color: transparent;
    background-color: #FAFAFA;
    border: 0;
}
/* .nav-link.active .btn-intro > span{
    font-weight: 700;
} */

.navbar .atticus-btn.btn:hover + .atticus-text .atticus-span {
    background: linear-gradient(103deg, #006400 24.9%, #98FB98 53.04%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.mr-header{
    margin-top: 1.68vh;
    margin-bottom: 0.6vh;
    position: relative;
    z-index: 10;
}
.mr-header .MRTitle{
    font-size: 36px;
    font-weight: 300;
}

.mr-Input{
  margin-bottom: 2rem !important;
  position: fixed;
  bottom: 0;
  width: 50% !important;
  left: 50%;
  transform: translateX(-50%);
}


.icon-features{
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    /* background: #FFF; */
    background-color: #D9D9D9;
    padding: 10px;
    z-index: 5;
    height: 40px;
    border:none;
    display: flex;
    justify-content: center;
    width: 40px;
    margin-top: 11px;
}

.icon-file{
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    /* background: #FFF; */
    background-color: #D9D9D9;
    padding: 10px;
    z-index: 5;
    height: 40px;
    border:none;
    /* display: flex;
    justify-content: center; */
    width: 40px;
    margin-top: 11px;
    margin-right: 11px;
}

.icon-fileAttach {
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    bottom: 56px;
    /* position: relative; */
    /* background: #FFF; */
    background-color: #D9D9D9;
    padding: 10px;
    z-index: 5;
    height: 40px;
    border:none;
    display: flex;
    justify-content: center;
    width: 40px;
    margin-top: 11px;
}   


.features-text{
    visibility: hidden;
    padding: 22px 0;
    margin-left: 7px;

    color: #000;

    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.132px;

}

.features-container {
    display: flex;
    position: relative
}

.add-file-text {
    /* visibility: hidden; */
    color: #000;

    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.132px;
}


.dropdown-menu .drop{
    background-color: transparent;
}
.dropdown-menu .drop:hover{
    background-color: transparent;
}

.ai-model-header .dropdown-ai-text{
    align-items: flex-start;
}



.ai-model-dropdown-title{
    display: flex;
    justify-content: space-between;
}

.ai-model-dropdown {
    margin-top: 14px;
    width: max-content;
    position: absolute;
    right: 47px;
    top: 5px;
}

.ai-model-header{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    bottom: 8px;
    font-family: 'Montserrat';
    font-size: 9px;
}

.ai-model-header-select-ai{
    font-family: 'Montserrat';
}

.color-header {
    color: #5DCDF5 !important;
}

.dropdown-select-text{
    color: #CFCFCF;
    font-weight: 400;
    font-size: 1.05rem;
}

.header-hover-ai-icon{
    display: flex;
    justify-content: flex-end;
    color: black;
    font-size: 1rem;
    font-family: 'Montserrat';
}


.dropdown-ai-text{
    color: #A7A7A7;
    font-size: 1.19rem;
}

.visible-title{
    visibility: visible;

}

.arrowIcon-dropdown{
    display: flex;
    align-items: center;
}

.aiDropWrap{
    display: flex;
}

.features-text:hover, 
.add-file-text:hover
{
    visibility: visible;
    opacity: 1; 
}

.inputBar .icon-features:focus{
    background-color: #D9D9D9 !important;
}

.icon-features:hover{
    background-color: #D9D9D9;
    filter: drop-shadow(-1px -1px 1px #FFF) drop-shadow(1px 1px 1px rgba(134, 134, 134, 0.25));
}

.icon-file-attach:hover{
    background-color: #D9D9D9;
    filter: drop-shadow(-1px -1px 1px #FFF) drop-shadow(1px 1px 1px rgba(134, 134, 134, 0.25));
}

.icon-file-attach {
    height: 35px !important;
    width: 35px !important;
    cursor: pointer;
}

.features-container .dropdown-toggle::after {
    display:none;
}
.features-container .dropdown-toggle[aria-expanded="false"],
.features-container .dropdown-toggle:active,
.features-container .dropdown-toggle.btn-not-focus
.feature-container .dropup
{
    background-color: #D9D9D9 !important;
    border-color: #D9D9D9 !important
} 
.features-container .dropdown-menu.show
{
    border: none;
    z-index: 1000;
    background-color: transparent;
    transform: translate(-15px, -35.75px) !important;
    -webkit-transform: translate(-15px, -35.75px) !important;
    -moz-transform: translate(-15px, -35.75px) !important;
    -ms-transform: translate(-15px, -35.75px) !important;
    -o-transform: translate(-15px, -35.75px) !important;
}

.features-container .dropdown-item{
    margin-bottom: 14px;
}

.custom-dropdown-toggle.btn-check:checked + .btn,
.custom-dropdown-toggle:not(.btn-check) + .btn:active,
.custom-dropdown-toggle.btn:first-child:active,
.custom-dropdown-toggle.btn.active,
.custom-dropdown-toggle.btn.show {
    background-color: #D9D9D9 !important;
  }


.features-container .dropdown-item:hover{
    background-color: transparent !important;

}


.dropdown-item:hover {
    cursor: pointer;
}

.feature-container .dropdown-toggle,
.feature-container .dropdown-toggle .btn-primary,
.feature-container .btn
{
    background-color: #D9D9D9  !important;
}

.dropdown-item:focus .feature-container .btn,
.dropdown-item:focus-within .feature-container .btn
{
    background-color: #D9D9D9 !important;
    border-color: #D9D9D9 !important
}

.icon-features:hover + .features-text,
.icon-file-attach:hover + .add-file-text
{
    visibility: visible;
    opacity: 1;

}

.text-bar-btn{
    display: flex;
    flex-direction: column;
    width: 46vw;
    margin: 0 15px;
    background-color: #fff;
}

.file-attached {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 55px;
    height: 64px;
    border-radius: 6px;
    background-color: #D9D9D9;
    margin: 9px 0;
    margin-left: 23px;
}

.cancel-icon-file {
    position: absolute;
    cursor: pointer;
    top: -1px;
    left: 35px;
}

.inputBar{
    display: flex;
    justify-content: center;
    z-index: 2;
    align-items: flex-end;
}




.feedBtnWrap{
    position: relative;
    width: fit-content;
    padding-right: 10px;
}


.text-area-btn button{
    background: linear-gradient(152.26deg, #495FAB 17.23%, #5DCDF5 93.4%);
    cursor: pointer;
    color: #fff ;
}

.btn-not-focus button{
    background: var(--Main-Blue, #495FAB);
    cursor: pointer;
    color: #fff !important;
}

.enter-text-btn button{
    background-color: #9D9D9D !important;
    color: #fff !important;
}
.enter-text-btn svg path{
    fill: white;
}
.tooltip-text{
    width: 90px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.btn-disable:disabled .svg {
    fill:white
}

.dropTitle{
    cursor: pointer;
    margin-top: 11px;
}

.dropTitle:hover{
    filter: drop-shadow(-1px -1px 1px #FFF) drop-shadow(1px 1px 1px rgba(134, 134, 134, 0.25));
}

.dropdown-item:hover{
    border-radius: 1px;
    background-color: rgba(217, 217, 217, 0.43);
}

.ai-model-icon{
    height: 40px;
    width: 40px;
    margin-top: 7px;
    border-radius: 512px;
}

.customDropBtn  button.btn {
    cursor: default !important;
    background-color: transparent;
    color: #111;
    border: 0;
    padding-top: 0;
    padding-right: 0;
}

.customDropBtn{
  display: flex;
  color: var(--color-bg-gray);
  position: relative;
}

.sign-in-btn{
    max-width: 75% !important;
    width: 100%;
    border-radius: 30px;
    padding: 5px 0;
    margin: 0 auto;
    z-index: 5;
}


.btn-div{
    display: flex;
    justify-content: end;
    margin-left: 8vw !important;
}

.sign_in_btn{
    outline: 0;
    appearance: none;
    color: #FFF;
    text-align: center;
    font-size: 96%;
    font-weight: 700;
    border: 0;
    letter-spacing: -0.198px;
    border-radius: 100px;
    box-shadow: 4px 4px 4px 0px rgba(87, 87, 87, 0.25), -4px -4px 4px 0px #FFF;
    margin-bottom: 10px;
    padding: 14px;
    width: 20%;
}

.sign_in_btn{
    background: linear-gradient(139deg, #5DCDF5 23.13%, #495FAB 83.94%);
    cursor: pointer;
    color: #fff;

}

.GPInputWrapperWidth{
    width: 0 !important
}
