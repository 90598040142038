.error_401 {
    border-radius: 5%;
    background: #FAFAFA;
    box-shadow: 4px 4px 4px 0px rgba(173, 173, 173, 0.25);
    width: 8%;
    display: flex;
    justify-content: center;
    padding: 2% 7%;
    position: absolute;
    margin-left: 10%;
    margin-top: 2%;
}

.text-p {
    position: absolute;
    background: white;
    border: 1px solid white;
    width: 11%;
    top: 88%;
    left: 30%;
    transform: translate(-50% ,-50%);
   
}

.text-404{
    position: absolute;
    color: white;
    top: 14%;
    left: 34%;
    transform: translate(-50% ,-50%);
}

.main-div{
    margin-top: 15%;
}

.q {
  position: absolute;
  margin-right: 25%;
  margin-left: -180px;
  margin-top: 5%;
  width: 10%;
}