
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&family=Inter:wght@700&family=Roboto+Slab&display=swap');
  body{
    font-family: 'Montserrat';
    background-color: #f9f9f9;
    overflow-y: hidden; 
    overflow-x: hidden;
  }
.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Media Query for Mobile */
.mobileViewPort {
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 24px;
  display: none;
}
@media screen and (max-width:799px) {
  .HyperPrompt{display: none;}
  .mobileViewPort{display: block;}
}