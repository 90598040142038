.auth_page_wrapper{
    min-height: 100vh;
    background-color: #FAFAFA;
    display: grid;
    grid-template-columns: 30vw auto;
    position: relative;
}
.auth_page_wrapper .photo_col{
    position: relative;
    height: 100vh;
    overflow: hidden;
    right: 5%;
}
.HP_header_logo{
    position: absolute;
    top: 3.1vh;
    left: 1.85vw;
    z-index: 4;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 15px;
}
.HP_header_logo span{
    font-size: 18px;
    font-weight: 400;
}
.photo_col .log_title{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 4.15vh;
    font-size: 1.67vw;
    font-weight: 400;
    width: 100%;
    color: #fff;
    text-align: center;
    z-index: 4;
}
.auth_page_wrapper .photo_col img{
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
}
.auth_page_wrapper .auth_col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_page_wrapper .auth_col .form_wrap {
    max-width: 350px;
    width: 100%;
    padding:  6.25vh 0;
    margin: auto;
}
.auth_page_wrapper .auth_col .form_wrap .log_title {
    color: #000;
    font-size: 55px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.605px;
}
.auth_page_wrapper .auth_col .form_wrap .log_title .log_sub {
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -0.352px;
    display: block;
}
.forget_password{
    margin-bottom: 25px;
    text-align: right;
}
.forget_password a{
    color: var(--color-primary);
}
.forget_password .forget_text{
    color: var(--color-primary);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.165px;
    text-decoration: none;
}
.auth_page_wrapper .auth_col .log_btn{
    outline: 0;
    appearance: none;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    border: 0;
    letter-spacing: -0.198px;
    border-radius: 100px;
    margin-bottom: 10px;
    padding: 14px;
    width: 100%;
    text-decoration: none;
}

.auth_page_wrapper .auth_col .log_btn.disabled{
    background: var(--color-active) !important;
    cursor: initial;
}

input[aria-disabled="true"], button[aria-disabled="true"] {
    background: var(--color-active) !important;
    cursor: initial;
    pointer-events: all !important;
}


.auth_page_wrapper .auth_col .log_btn{
    background: linear-gradient(152.26deg, #495FAB 17.23%, #5DCDF5 93.4%);
    cursor: pointer;
    color: #fff;
}

.auth_page_wrapper .auth_col .log_btn.bg_white{
    background: var(--color-bg-drawer-gray);
    color: var(--color-active);
}


.auth_page_wrapper .auth_col .log_btn.btn_email{
    background: var(--color-grey) !important;
    color: var(--color-white) !important;
    box-shadow: none !important;
}

.auth_page_wrapper .auth_col .log_btn:hover {
    box-shadow: 
        4px 4px 4px 0px rgba(87, 87, 87, 0.25),
        -4px -4px 4px 0px rgb(255, 255, 255); 
}

.auth_page_wrapper .auth_col .log_btn:focus {
    box-shadow: 
        4px 4px 4px 0px rgba(87, 87, 87, 0.25),
        -4px -4px 4px 0px rgb(255, 255, 255); 
}


.alt_auth_wrapper  {
    color: var(--color-bg-gray);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.165px;
    margin-top: 32px;
}
.alt_auth_wrapper .divider {
    text-align: center;
    position: relative;
    margin-bottom: 15px;
}
.alt_auth_wrapper .divider:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: var(--color-bg-gray);
    height: 1px;
    width: 100%;
}
.alt_auth_wrapper .divider span {
    background-color: #FAFAFA;
    padding: 0 14px;
    color: var(--color-bg-gray);
    z-index: 1;
    position: relative;
}
.logForm .inputBox{
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    /* background: #FFF; */
    /* box-shadow: -1px -1px 1px 0px #FFF, 1px 1px 1px 0px rgba(134, 134, 134, 0.25); */
    /* padding: 9px 14px; */
}
.auth_page_wrapper .auth_col .logForm .inputBox.bg_transtarent{
    background: transparent;
    box-shadow: none;
    padding: 0;
}
.auth_page_wrapper .auth_col .logForm .inputBox .tac_Checkbox{
    display: flex;
    align-items: center;
    gap: 10px;
}
.auth_page_wrapper .auth_col .logForm .inputBox .input_tac_text{
    color: #000;
    font-size: 12px;
}
.auth_page_wrapper .auth_col .logForm .inputBox .input_tac_text a{
    color: #000;
}
.auth_page_wrapper .auth_col .logForm .inputBox:not(:last-child){
    margin-bottom: 20px;
}

.auth_page_wrapper .auth_col .logForm .inputBox .email-error{
    border: 1px solid red;
    border-radius: 5px;
}
.auth_page_wrapper .auth_col .logForm .inputBox input{
    outline: none;
    appearance: none;
    border: 0;
    color: #0C1B3A;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
    letter-spacing: -0.198px;
}

.auth_page_wrapper .auth_col .logForm .inputBox .tac_Checkbox input{
    
    appearance: auto;
    
}
.auth_page_wrapper .auth_col .logForm .inputBox svg{
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
}
.auth_page_wrapper .auth_col .logForm .inputBox label{
color: var(--color-bg-gray);
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 100%; 
letter-spacing: -0.132px;
}
.loginForeground{
    position: relative !important;
    z-index: 3 !important;
    right: 0%;
}
.loginMiddleground{
    position: absolute !important;
    z-index: 2 !important;
}
.loginBackground{
    position: absolute !important;
    z-index: 1 !important;
    overflow: hidden;
    width: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
}

.passwordChecklist{
    border-radius: 5px;
    background: #FAFAFA;
    padding: 14px;
    margin: 0px 0 40px;
    color: #060E1E;
    font-size: 12px;
}
.passwordChecklist:hover{
    box-shadow: -2px -2px 2px 0px #FFF, 2px 2px 2px 0px rgba(134, 134, 134, 0.25);
}
.passwordChecklist ul li span{
    font-weight: 600;
}
.passwordChecklist ul li.invalid span{
    color: #060E1E;
    opacity: 1 !important;
}
.passwordChecklist li.valid span {
  background: linear-gradient(124deg, #233ea1 -1.39%, #5DCDF5 22.13%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.passConfirmTitle{
    margin-bottom: 20px;
}

.inputBox .gp-input-group{
    height: 45px;
}

.inputBox.custom .gp-input-filled input:focus{
    border: 1px solid #5DCDF5;
}

.inputBox.custom .gp-input-filled.email-error input:focus{
    border: none;
}

.filterDiv{
    display: flex;
    margin-left: 1px;
    max-width: calc(520px + (582 - 520) * ((100vw - 320px) / (1920 - 320)));
    margin: 0 auto;
    min-width: 556px;
    width: 46vw;
}


.sortDiv > span > button {
    color: gray;
    cursor: pointer;
    background: transparent;
    border: none;
}


.sortDiv > .btn,
.sortDiv > span
{
    margin: 0;
    padding-left: 0;
    font-size: 16px;
    font-weight: 600 !important;
    color: #afafaf;
    cursor: pointer;
    background: transparent;
    border: none;
}

.dropdownDiv  button.btn {
    background-color: transparent;
    color: #111;
    border: 0;
    margin-left: 1.5vw;
}

.dropdownDiv{
  color: var(--color-bg-gray)
}
.dropdown-toggle::after {
    display: none ;
}

.dropdown-response-type{
    display: inline ;

}
.dropdown-response-type .dropdown-toggle{
    background-color: transparent ;
    color: gray;
    border: none;
}


.dropdownDiv .dropdown-item:hover {
    background-color: rgb(180 229 247 / 40%);
    font-weight: bold;
}


.title-dropdown-sort-by{
    display: flex;
    justify-content: flex-end;
    color: black;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 13px;
}

.custom-dropdown-button{
    cursor: pointer;
}

.custom-dropdown-button .dropdown-menu.show{
    border: none;
    border-radius: 13px;
    padding: 10px;
    padding-top: 8px;
    width: 140px;
    min-width: 10px;
}

.custom-dropdown-button .dropdown-toggle:focus,
.dropdown-response-type .dropdown-toggle:focus {
    background-color: transparent; 
    color: gray;
}

.selected-item-icon {
    color: rgb(50, 189, 199)  !important;
}

.selected-item-icon svg g path{
    fill: rgba(93, 205, 245, 0.4) !important;
}

.selected-item-icon svg path{
    fill: rgba(93, 205, 245, 0.4) !important;
}

.dropDownItems{
    margin: 0 9%;
    margin-right: 0;
}

.dropdown-style {
    position: relative;
    display: inline-block;
  }

  .dropdown-header-style{
    font-size: 1rem;
  }


  
  .dropdown-content {
    display: block !important;
    position: absolute;
    width: 154px;
    padding: 10px;
    top: 100%;
    left: 0;
    display: none;
    z-index: 1;
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.dropdown-content-ai-model .dropdown-option:hover,
.dropdown-content-response-type .dropdown-option:hover,
.dropdownDiv .dropdown-item:hover
{
    background-color: rgb(180 229 247 / 40%);
    font-family: 'Montserrat';
}

.dropdownDiv .dropdown-item{
    color: #060E1E;
    font-weight: 300;
    font-size: 12px;
    font-family: 'Montserrat';
    padding: 0;
}


.dropdown-content-response-type .dropdown-option
{
    color: #060E1E;
    font-weight: 300;
    font-size: 12px;
    font-family: 'Montserrat';
    width: 105px !important;
    line-height: 130%;
}

.dropdown-content-response-type{
    width: 125px;
}
 

  .ai-dropdown-options{
    width: 133px !important;
    display: flex;
    color: #060E1E;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Montserrat';
    line-height: 130%;
  }


  .dropdown-option {
    width: 16vw;
    display: flex;
  }

  
  .dropdown-option:hover {
    background-color: #f0f0f0;
  }


.dropdown-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-dropdown {
    display: flex;
    justify-content: flex-start;
    color: black;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 13px;
}

.item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 115px;
}

.item-name {
    margin-right: 10px; 
}


.sortDiv{
    padding-top: 3px;
}

.sortDiv > .selected-tab {
    color: black !important;
    font-weight: bold;
}

.cancel-icon {
    visibility: hidden !important;
}

.searchIcon{
    display: flex !important;
    width: 36px;
    height: 36px;
    align-items: center;
    margin-right: 17px;
    padding: 4px 8px !important;
    cursor: pointer !important;
    background-color: #5DCDF5 !important;
    border: 1px solid #5DCDF5 !important;
    border-radius: 22px !important;
    -webkit-border-radius: 22px !important;
    -moz-border-radius: 22px !important;
    -ms-border-radius: 22px !important;
    -o-border-radius: 22px !important;
}
.searchIcon:hover {
    border-radius: 18px;
    background: var(--bjelly, #5DCDF5);
    box-shadow: 2px 2px 2px 0px rgba(134, 134, 134, 0.25), -2px -2px 2px 0px #FFF;
}
.searchIcon:focus {
    border-radius: 18px;
    background: var(--bjelly, #5DCDF5);
    box-shadow: 2px 2px 2px 0px rgba(134, 134, 134, 0.25), -2px -2px 2px 0px #FFF;
  }

.sortDiv-child-btn:focus {
    background-color: transparent !important;
    --bs-btn-hover-color: gray !important;
    --bs-btn-active-color: black !important;
    --bs-btn-hover-bg: white !important;
}

.MRLog_button_wrapper button.btn:focus{
    background-color: transparent !important;
}

.sortDiv-child:focus {
     background-color: transparent !important;
     --bs-btn-hover-color: gray !important;
     --bs-btn-active-color: black !important;
     --bs-btn-hover-bg: white !important;
}

.icon-btn {
     --bs-btn-bg: white !important;
     --bs-btn-border-color: white !important;
}



.sortDiv-child:hover {
    background-color: transparent !important;
    --bs-btn-active-color: gray !important;
    --bs-btn-hover-color: gray !important;
     --bs-btn-hover-bg: white !important;
}

.cancel-btn{
    padding: 0 !important;
}

.cancel-btn:focus {
    border-radius: 20% !important;
    background-color: transparent !important;
    --bs-btn-hover-color: white !important;
    --bs-btn-hover-bg: white !important;
}

.cancel-btn:hover {
    border-radius: 20% !important;
    background-color: transparent !important;
    --bs-btn-hover-color: white !important;
    --bs-btn-hover-bg: white !important;
}

.search-component{
    margin-bottom: 4% !important;
    margin-top: 0 !important;
    min-width: 556px;
    width: 46vw;
}

.search-component .input-group{
    padding: 6px 0;
}

.search-component:hover{
    box-shadow: -4px -4px 4px 0px #FFF, 4px 4px 4px 0px rgba(87, 87, 87, 0.25);
    border-radius: 30px;
    background: #FFF;

}

.loading .progress {
    max-width: calc(520px + (582 - 520) * ((100vw - 320px) / (1920 - 320)));
    margin: 0 auto;
}
.log_btn.bg_white {
    display: inline-block;
    padding: 10px 20px; 
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    text-decoration: none; 
    border: 1px solid #cccccc; 
    border-radius: 5px;
    font-size: 16px; 
    font-weight: bold; 
    cursor: pointer;
    transition: all 0.3s ease; 
}

.log_btn.bg_white:hover {
    background-color: #e6e6e6;
    color: #333333; 
}

.form_scroll{
    overflow-Y: auto;
    height: 100vh;
    display: flex;
    align-items: center ;
    width: 100%;
}